import {
  BottomBar,
  DiscoverMoreButton,
  HotTopicBanner,
  useWindowScrollPosition,
  theme,
} from '@zappyrent/hundop';
import { graphql } from 'gatsby';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import { rem } from 'polished';
import React, { useRef } from 'react';
import Media from 'react-media';
import { QueryClient, QueryClientProvider } from 'react-query';
import styled from 'styled-components';

import BestSolution from '../components/BestSolution';
import Help from '../components/Help';
import Layout from '../components/Layout';
import LogoShowcase from '../components/LogoShowcase';
import LookingForAHouseIsFastAndEasy from '../components/LookingForAHouseIsFastAndEasy';
import OurCities from '../components/OurCities';
import Seo from '../components/Seo';

const StyledSectionPrimary = styled.section`
  margin-bottom: ${rem('80px')};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
    margin-bottom: ${rem('120px')};
  }
`;

const StyledSectionTertiary = styled.section`
  margin-bottom: ${rem('80px')};
`;

const StyledSection = styled.section`
  scroll-margin-top: ${rem('136px')};
`;

const StyledDiscoverMoreButtonWrapper = styled.div`
  margin-top: ${rem('-24px')};
  margin-bottom: ${rem('-8px')};
`;

const HomePage = (): JSX.Element => {
  const formRef = useRef<HTMLDivElement>(null);
  const { language } = React.useContext(I18nextContext);
  const { t } = useTranslation(['product', 'seo']);
  const { y } = useWindowScrollPosition();
  const queryClient = new QueryClient();

  return (
    <Layout
      superHeader={
        <HotTopicBanner
          backgroundColor={theme.colors.landlord.main}
          ctaLabel={t('product:rentWithUs')}
          ctaUrl={`${process.env.GATSBY_SITE_URL}/it/pubblica-annuncio-gratis`}
          firstLabel={t('product:areYouALandlord')}
          secondLabel={t('product:weAlwaysGuaranteeThePaymentOfTheRent')}
        />
      }
      user="tenant"
    >
      <Seo
        canonical={`/${language}`}
        hreflang={`${language}`}
        title={t('seo:titleHomePage')}
        description={t('seo:descriptionHomePage')}
        ogImageUrl={`${process.env.GATSBY_CDN_URL}/opengraph/zappyrent-logo-green-500x500.png`}
        ogImageHeight="500"
        ogImageWidth="500"
        schemaOrg={`
        {
          "@context": "https://schema.org/",
          "@type": "Service",
          "serviceType": "${t('seo:schemaOrgServiceTypeHomePage')}",
          "name": "${t('seo:schemaOrgNameHomePage')}",
          "description": "${t('seo:schemaOrgDescriptionHomePage')}",
          "url": "https://www.zappyrent.com",
          "logo": "https://cdn-website-assets.s3.eu-central-1.amazonaws.com/assets/zappy-logo.png",
          "sameAs": [
            "https://www.facebook.com/Zappyrent",
            "https://www.instagram.com/zappyrent/",
            "https://www.linkedin.com/company/zappyrent/"
          ],
          "founder": [
            {
              "@type": "Person",
              "name": "Lino Leonardi"
            },
            {
              "@type": "Person",
              "name": "Antonino Leonardi"
            }
          ],
          "provider": {
            "@type": "Organization",
            "name": "Zappyrent"
          },
          "areaServed": {
            "@type": "State",
            "name": "Italy"
          }
        }`}
      />
      <QueryClientProvider client={queryClient}>
        <article>
          <StyledSectionTertiary id="looking-for-a-house-is-fast-and-easy">
            <div ref={formRef}>
              <LookingForAHouseIsFastAndEasy />
            </div>
            <Media
              queries={{
                desktop: `(min-width: ${theme.breakpoints.l})`,
              }}
              defaultMatches={{ desktop: true }}
            >
              {matches => (
                <>
                  {matches.desktop && (
                    <StyledDiscoverMoreButtonWrapper>
                      <DiscoverMoreButton
                        tag="a"
                        href={`#best-solution`}
                        title={t('product:discoverMore')}
                      />
                    </StyledDiscoverMoreButtonWrapper>
                  )}
                </>
              )}
            </Media>
          </StyledSectionTertiary>

          <StyledSection id="best-solution">
            <BestSolution />
          </StyledSection>

          <StyledSectionPrimary id="about-us">
            <LogoShowcase />
          </StyledSectionPrimary>

          <StyledSectionPrimary id="our-cities">
            <OurCities />
          </StyledSectionPrimary>

          <StyledSectionPrimary id="help">
            <Help />
          </StyledSectionPrimary>

          <Media
            queries={{
              mobile: `(max-width: calc(${theme.breakpoints.l} - 1px))`,
            }}
            defaultMatches={{ mobile: false }}
          >
            {matches => (
              <>
                {matches.mobile &&
                  y >
                    (!!formRef.current &&
                      formRef.current?.offsetTop +
                        formRef.current?.clientHeight / 1.1) && (
                    <BottomBar
                      fixed={true}
                      href="#form"
                      tag="a"
                      user="tenant"
                      title={t('product:searchAPlaceToRent')}
                    >
                      {t('product:searchAPlaceToRent')}
                    </BottomBar>
                  )}
              </>
            )}
          </Media>
        </article>
      </QueryClientProvider>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default HomePage;
