import { Link } from 'gatsby';
import { rem } from 'polished';
import styled from 'styled-components';

export const StyledHelp = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${rem('32px')};
  border: 1px solid ${({ theme }) => theme.colors.cross.lightGray};
  border-radius: ${rem('16px')};
  padding: ${rem('32px')};
`;

export const StyledHelpImage = styled.div`
  background-color: ${({ theme }) => theme.colors.landlord.main};
  height: 100%;
  border-radius: ${rem('12px')};
  padding: ${rem('32px')};

  & svg {
    width: ${rem('56px')};
    height: auto;

    & path {
      fill: ${({ theme }) => theme.colors.main};
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    display: none;
  }
`;

export const StyledHelpContent = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  gap: ${rem('12px')};

  * {
    margin: 0;
  }
`;

export const StyledHelpContentLink = styled(Link)`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.landlord.main};
  font-weight: 700;
  margin-top: ${rem('8px')};
`;
