import { CardShowcase, Grid, Heading, theme } from '@zappyrent/hundop';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import { upperFirst } from 'lodash';
import React from 'react';

import { cities, getListingUri, properties } from '../../lib/config';
import { StyledHeadingWrapper } from './OurCities.styles';

const OurCities = (): JSX.Element => {
  const { language } = React.useContext(I18nextContext);
  const { t } = useTranslation(['a11y', 'product', 'navigation']);

  return (
    <>
      <Grid container>
        <StyledHeadingWrapper>
          <Heading sm="24px" md="32px" lg="32px" tag="h3">
            {t('product:ourCities')}
          </Heading>
        </StyledHeadingWrapper>
      </Grid>
      <CardShowcase
        ariaLabels={{
          close: t('a11y:close'),
          seeNext: t('a11y:seeNext'),
          seePrevious: t('a11y:seePrevious'),
        }}
        content={cities.map(city => {
          const cityLabel = t(`geo:${city}`);
          const titleCityLabel = upperFirst(cityLabel);

          return {
            content: properties
              /* TODO remove the following filter when DB types 
              will be in sync with listing types */
              .filter(property => property.key !== 'entireProperty')
              .map(property => {
                const propertyLabel = t(`product:${property.key}`);
                const slugLabel = t(
                  `navigation:slug${upperFirst(property.key)}`,
                );
                return {
                  text: propertyLabel,
                  title: `${propertyLabel} ${t(
                    'product:atLocation',
                  )} ${titleCityLabel}`,
                  url: getListingUri(language, cityLabel, slugLabel),
                };
              }),
            desktopImageSizes: `
              (max-width: calc(${theme.breakpoints.xl} - 1px)) calc(21.25vw - 0.5rem),
              (max-width: calc(${theme.breakpoints.xxl} - 1px)) calc(18.375vw - 0.5rem),
              calc(18.375vw - 0.5rem)
            `,
            desktopImageSrc: `${process.env.GATSBY_CDN_URL}/hp/our-cities-desktop-${city}-1042.jpg`,
            desktopImageSrcSet: `
              ${process.env.GATSBY_CDN_URL}/hp/our-cities-desktop-${city}-286.jpg 286w,
              ${process.env.GATSBY_CDN_URL}/hp/our-cities-desktop-${city}-521.jpg 521w,
              ${process.env.GATSBY_CDN_URL}/hp/our-cities-desktop-${city}-572.jpg 572w,
              ${process.env.GATSBY_CDN_URL}/hp/our-cities-desktop-${city}-1042.jpg 1042w,
            `,
            mobileImageSizes: `
              (max-width: calc(${theme.breakpoints.l} - 1px)) 119px
            `,
            mobileImageSrc: `${process.env.GATSBY_CDN_URL}/hp/our-cities-mobile-${city}-238.jpg`,
            mobileImageSrcSet: `
              ${process.env.GATSBY_CDN_URL}/hp/our-cities-mobile-${city}-119.jpg 119w,
              ${process.env.GATSBY_CDN_URL}/hp/our-cities-mobile-${city}-238.jpg 238w,
            `,
            title: titleCityLabel,
          };
        })}
        ctaLabel={t('product:showLocations')}
        headingLabel={t('product:whatDoYouNeed')}
        lazyLoad
        user="tenant"
      />
    </>
  );
};

export default OurCities;
