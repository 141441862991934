import {
  CtaButton,
  Grid,
  Heading,
  Legal,
  MagicSearch,
  Papers,
  Payment,
  Refund,
  Search,
  Visit,
  Weakness,
  theme,
} from '@zappyrent/hundop';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { toUpper } from 'lodash';
import React from 'react';
import FadeIn from 'react-lazyload-fadein';
import Media from 'react-media';

import SequenceList from '../UI/SequenceList';
import {
  StyledBlocks,
  StyledCtaButtonWrapper,
  StyledGridWrapper,
  StyledImage,
  StyledImageWrapper,
  StyledImageBlock,
  StyledTextBlock,
  StyledTextWrapper,
} from './BestSolution.styles';

const BestSolution = (): JSX.Element => {
  const { t } = useTranslation();
  const fadeInDuration = 300;
  const fadeInOffset = 150;
  const landlordContent = {
    image: {
      alt: t('product:whyChooseZappyrent'),
      src: `${process.env.GATSBY_CDN_URL}/hp/best-solution-for-landlords-1024.jpg`,
      sizes: `50vw`,
      srcSet: `
        ${process.env.GATSBY_CDN_URL}/hp/best-solution-for-landlords-496.jpg 496w,
        ${process.env.GATSBY_CDN_URL}/hp/best-solution-for-landlords-992.jpg 992w,
        ${process.env.GATSBY_CDN_URL}/hp/best-solution-for-landlords-1024.jpg 1024w,
        ${process.env.GATSBY_CDN_URL}/hp/best-solution-for-landlords-2048.jpg 2048w,
      `,
    },
    list: [
      {
        icon: <Weakness />,
        iconWidth: '36px',
        text: t('product:publishYourAd'),
        title: t('product:noConstrains'),
      },
      {
        icon: <MagicSearch />,
        iconWidth: '36px',
        text: t('product:ifTenantPassesCreditCheck'),
        title: t('product:inDepthAnalysis'),
      },
      {
        icon: <Payment />,
        iconWidth: '38px',
        text: t('product:weWillPayYouTheFee'),
        title: t('product:guaranteedPayments'),
      },
      {
        icon: <Legal />,
        iconWidth: '38px',
        text: t('product:weWontLeaveYouAlone'),
        title: t('product:legalAssistanceIncluded'),
      },
    ],
  };
  const tenantContent = {
    image: {
      alt: t('product:whyChooseZappyrent'),
      src: `${process.env.GATSBY_CDN_URL}/hp/best-solution-for-tenants-1024.jpg`,
      sizes: `50vw`,
      srcSet: `
        ${process.env.GATSBY_CDN_URL}/hp/best-solution-for-tenants-496.jpg 496w,
        ${process.env.GATSBY_CDN_URL}/hp/best-solution-for-tenants-992.jpg 992w,
        ${process.env.GATSBY_CDN_URL}/hp/best-solution-for-tenants-1024.jpg 1024w,
        ${process.env.GATSBY_CDN_URL}/hp/best-solution-for-tenants-2048.jpg 2048w,
      `,
    },
    list: [
      {
        icon: <Search />,
        iconWidth: '36px',
        text: t('product:chooseInAThousandPosts'),
        title: t('product:searchForAnApartment'),
      },
      {
        icon: <Visit />,
        iconWidth: '36px',
        text: t('product:chooseInFullAutonomy'),
        title: t('product:visitTheApartment'),
      },
      {
        icon: <Papers />,
        iconWidth: '38px',
        text: t('product:signTheContractOnlineAndGetReadyToMove'),
        title: t('product:signTheContractOnline'),
      },
      {
        icon: <Refund />,
        iconWidth: '38px',
        text: t('product:forgetYourWorries'),
        title: t('product:handlePaymentsWithoutStress'),
      },
    ],
  };

  return (
    <Media
      queries={{
        mobile: `(max-width: calc(${theme.breakpoints.l} - 1px))`,
        desktop: `(min-width: ${theme.breakpoints.l})`,
      }}
      defaultMatches={{ desktop: true }}
    >
      {matches => (
        <>
          <StyledBlocks>
            <StyledTextBlock>
              <StyledGridWrapper>
                <Grid container>
                  <StyledTextWrapper>
                    <Heading sm="24px" md="24px" lg="32px" tag="h2">
                      <Trans
                        components={{ br: <br />, span: <span /> }}
                        i18nKey="product:bestSolutionForTenants"
                        t={t}
                      />
                    </Heading>
                    <SequenceList user="tenant" content={tenantContent.list} />
                    <StyledCtaButtonWrapper>
                      <CtaButton
                        href="#form"
                        tag="a"
                        title={t('product:searchAPlaceToRent')}
                        user="tenant"
                        width="100%"
                      >
                        {toUpper(t('product:searchAPlaceToRent'))}
                      </CtaButton>
                    </StyledCtaButtonWrapper>
                  </StyledTextWrapper>
                </Grid>
              </StyledGridWrapper>
            </StyledTextBlock>
            {matches.desktop && (
              <StyledImageBlock>
                <StyledImageWrapper>
                  <FadeIn duration={fadeInDuration} offset={fadeInOffset} once>
                    {onload => (
                      <StyledImage {...tenantContent.image} onLoad={onload} />
                    )}
                  </FadeIn>
                  <noscript>
                    <StyledImage {...tenantContent.image} />
                  </noscript>
                </StyledImageWrapper>
              </StyledImageBlock>
            )}
          </StyledBlocks>
          <StyledBlocks>
            {matches.desktop && (
              <StyledImageBlock>
                <StyledImageWrapper>
                  <FadeIn duration={fadeInDuration} offset={fadeInOffset} once>
                    {onload => (
                      <StyledImage {...landlordContent.image} onLoad={onload} />
                    )}
                  </FadeIn>
                  <noscript>
                    <StyledImage {...landlordContent.image} />
                  </noscript>
                </StyledImageWrapper>
              </StyledImageBlock>
            )}
            <StyledTextBlock>
              <StyledGridWrapper>
                <Grid container justifyContent="flex-end">
                  <StyledTextWrapper>
                    <Heading sm="24px" md="24px" lg="32px" tag="h2">
                      <Trans
                        components={{ br: <br />, span: <span /> }}
                        i18nKey="product:bestSolutionForLandlords"
                        t={t}
                      />
                    </Heading>
                    <SequenceList
                      user="landlord"
                      content={landlordContent.list}
                    />
                    <StyledCtaButtonWrapper>
                      <CtaButton
                        href={`${process.env.GATSBY_SITE_URL}/it/pubblica-annuncio-gratis`}
                        tag="a"
                        title={t('navigation:publishAListing')}
                        user="landlord"
                        width="100%"
                      >
                        {toUpper(t('product:rentWithUs'))}
                      </CtaButton>
                    </StyledCtaButtonWrapper>
                  </StyledTextWrapper>
                </Grid>
              </StyledGridWrapper>
            </StyledTextBlock>
          </StyledBlocks>
        </>
      )}
    </Media>
  );
};

export default BestSolution;
