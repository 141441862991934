import { getAspectRatioRate } from '@zappyrent/hundop';
import { cover, rem } from 'polished';
import styled from 'styled-components';

export const StyledBlocks = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
    position: relative;
    display: flex;
    flex-direction: row;
  }
`;

export const StyledCtaButtonWrapper = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
    width: ${rem('240px')};
  }
`;

export const StyledGridWrapper = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
    position: absolute;
    right: 0;
    left: 0;
  }
`;

export const StyledImage = styled.img`
  ${cover()}
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
    padding-bottom: ${getAspectRatioRate(1, 1)};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    padding-bottom: ${getAspectRatioRate(4, 3)};
  }
`;

export const StyledTextBlock = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
    display: flex;
    align-items: center;
    flex: 0 0 50%;
  }
`;
export const StyledTextWrapper = styled.div`
  width: 100%;
  margin-bottom: ${rem('72px')};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
    width: 50%;
    padding-right: ${rem('80px')};
    padding-left: 0;
    margin: 0;
    text-align: left;
  }
`;

export const StyledImageBlock = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
    display: flex;
    flex: 0 0 50%;

    & + ${StyledTextBlock} {
      ${StyledTextWrapper} {
        @media screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
          width: 50%;
          padding-left: ${rem('80px')};
          padding-right: 0;
          margin: 0;
          text-align: right;
        }

        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }
`;
