import { Grid, Support } from '@zappyrent/hundop';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import {
  StyledHelp,
  StyledHelpContent,
  StyledHelpContentLink,
  StyledHelpImage,
} from './Help.styles';

const Help = (): JSX.Element => {
  const { language } = React.useContext(I18nextContext);
  const { t } = useTranslation('product');

  return (
    <Grid container>
      <StyledHelp>
        <StyledHelpImage>
          <Support />
        </StyledHelpImage>

        <StyledHelpContent>
          <h2>{t('needHelp')}</h2>
          <p>{t('checkOutOurFAQ')}</p>
          <StyledHelpContentLink to={`/${language}/help`}>
            {t('requestForHelp')}
          </StyledHelpContentLink>
        </StyledHelpContent>
      </StyledHelp>
    </Grid>
  );
};

export default Help;
