import {
  Box,
  Grid,
  Heading,
  Slider,
  SliderProvider,
  theme,
} from '@zappyrent/hundop';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import Media from 'react-media';

import FindAnApartment from '../FindAnApartment';
import {
  StyledBody,
  StyledFindAnApartmentWrapper,
  StyledHead,
  StyledSliderImage,
  StyledSliderInnerWrapper,
  StyledSliderOuterWrapper,
  StyledWrapper,
} from './LookingForAHouseIsFastAndEasy.styles';
import {
  IBodyWrapperProps,
  IHeaderWrapperProps,
} from './LookingForAHouseIsFastAndEasy.types';

const images = [
  {
    src: `${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-01-1024.jpg`,
    sizes: `
      (max-width: calc(${theme.breakpoints.l} - 1px)) 100vw,
      (max-width: calc(${theme.breakpoints.xl} - 1px)) calc(75vw - 0.5rem),
      calc(50vw - 0.5rem)
    `,
    srcSet: `
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-01-891.jpg 891w,
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-01-991.jpg 991w,
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-01-1024.jpg 1024w,
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-01-1912.jpg 1912w,
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-01-1782.jpg 1782w,
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-01-1982.jpg 1982w,
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-01-2048.jpg 2048w,
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-01-3824.jpg 3824w
    `,
  },
  {
    src: `${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-02-1024.jpg`,
    sizes: `
      (max-width: calc(${theme.breakpoints.l} - 1px)) 100vw,
      (max-width: calc(${theme.breakpoints.xl} - 1px)) calc(75vw - 0.5rem),
      calc(50vw - 0.5rem)
    `,
    srcSet: `
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-02-891.jpg 891w,
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-02-991.jpg 991w,
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-02-1024.jpg 1024w,
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-02-1782.jpg 1782w,
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-02-1912.jpg 1912w,
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-02-1982.jpg 1982w,
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-02-2048.jpg 2048w,
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-02-3824.jpg 3824w
    `,
  },
  {
    src: `${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-03-1024.jpg`,
    sizes: `
      (max-width: calc(${theme.breakpoints.l} - 1px)) 100vw,
      (max-width: calc(${theme.breakpoints.xl} - 1px)) calc(75vw - 0.5rem),
      calc(50vw - 0.5rem)
    `,
    srcSet: `
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-03-891.jpg 891w,
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-03-991.jpg 991w,
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-03-1024.jpg 1024w,
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-03-1782.jpg 1782w,
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-03-1912.jpg 1912w,
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-03-1982.jpg 1982w,
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-03-2048.jpg 2048w,
      ${process.env.GATSBY_CDN_URL}/hp/looking-for-a-house-is-fast-and-easy-03-3824.jpg 3824w
    `,
  },
];

const HeaderWrapper = ({ title }: IHeaderWrapperProps) => (
  <StyledHead>
    <Heading sm="32px" md="56px" lg="56px" tag="h1">
      {title}
    </Heading>
  </StyledHead>
);

const BodyWrapper = ({
  children,
  headerTitle,
  isMobile,
}: IBodyWrapperProps) => {
  return isMobile ? (
    <>
      <Grid container>
        <HeaderWrapper title={headerTitle} />
      </Grid>
      {children}
    </>
  ) : (
    <Grid container alignItems="center" position="relative">
      <HeaderWrapper title={headerTitle} />
      {children}
    </Grid>
  );
};

const LookingForAHouseIsFastAndEasy = (): JSX.Element => {
  const { t } = useTranslation(['navigation', 'product']);

  return (
    <StyledWrapper>
      <Media
        queries={{
          mobile: `(max-width: calc(${theme.breakpoints.l} - 1px))`,
          desktop: `(min-width: ${theme.breakpoints.l})`,
        }}
        defaultMatches={{ desktop: true }}
      >
        {matches => (
          <BodyWrapper
            headerTitle={
              <Trans
                components={{ br: <br />, span: <span /> }}
                i18nKey="product:lookingForAHouseIsFastAndEasy"
                t={t}
              />
            }
            isMobile={matches.mobile}
          >
            <StyledBody>
              <Box lg={6} xl={4}>
                <SliderProvider datas={images}>
                  <StyledSliderOuterWrapper>
                    <StyledSliderInnerWrapper>
                      <Slider
                        autoplay
                        autoplayInterval={5000}
                        animateTransitions
                        hasProgressBar
                      >
                        {images.map((item, index) => {
                          const { sizes, src, srcSet } = item;

                          return (
                            <StyledSliderImage
                              alt={t('navigation:lookingForARent')}
                              key={index}
                              src={src}
                              srcSet={srcSet}
                              sizes={sizes}
                            />
                          );
                        })}
                      </Slider>
                    </StyledSliderInnerWrapper>
                  </StyledSliderOuterWrapper>
                </SliderProvider>
              </Box>
            </StyledBody>

            <StyledFindAnApartmentWrapper id="form">
              {matches.mobile && (
                <Grid container>
                  <FindAnApartment />
                </Grid>
              )}
              {matches.desktop && (
                <Box lg={3} xl={2}>
                  <FindAnApartment />
                </Box>
              )}
            </StyledFindAnApartmentWrapper>
          </BodyWrapper>
        )}
      </Media>
    </StyledWrapper>
  );
};

export default LookingForAHouseIsFastAndEasy;
