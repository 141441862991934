import {
  CtaButton,
  DropDown,
  Fieldset,
  FormNotice,
  Heading,
  PropertyCity,
  PropertyType,
  RangeSlider,
  Spacer,
} from '@zappyrent/hundop';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import { debounce, isEqual, upperCase, upperFirst } from 'lodash';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  cities as defaultCities,
  getListingUri,
  properties,
} from '../../lib/config';
import { getCities } from '../../services/city';
import { IGetCity } from '../../services/city.types';
import AsyncSelectInput from '../UI/AsyncSelectInput';
import { IAsyncSelectInputOption } from '../UI/AsyncSelectInput/AsyncSelectInput.types';
import {
  StyledLandordLink,
  StyledLink,
  StyledParagraph,
  StyledWrapper,
} from './FindAnApartment.styles';
import { IFindAnApartmentFromProps } from './FindAnApartment.types';

const FindAnApartment = (): JSX.Element => {
  const { language } = React.useContext(I18nextContext);

  const { t } = useTranslation([
    'a11y',
    'form',
    'geo',
    'navigation',
    'product',
  ]);
  const priceRange = [0, 2500];
  const { control, formState, handleSubmit, watch } =
    useForm<IFindAnApartmentFromProps>({
      defaultValues: {
        price: priceRange,
      },
    });
  const onSubmit = async (data: IFindAnApartmentFromProps) => {
    const { city, type, price: rawPrice } = data;
    const cityLabel = t(`geo:${city.toLowerCase()}`);
    const slugLabel = t(`navigation:slug${upperFirst(type)}`);
    const price = isEqual(rawPrice, priceRange) ? null : rawPrice;
    const priceLabel = t(`product:price`);
    const normalizedPrice =
      price && price.map(item => (item === 2500 ? 5000 : item)).join('-');
    let uri = getListingUri(language, cityLabel);

    if (type) uri = getListingUri(language, cityLabel, slugLabel);
    if (type && price) uri += `?${priceLabel}=${normalizedPrice}`;

    window.location.href = uri;
  };
  const cityValue = watch('city');
  const searchDisabled = useMemo(() => !cityValue, [cityValue]);

  const loadOptions = debounce(
    (
      inputValue: string,
      callback: (options: IAsyncSelectInputOption[]) => void,
    ) => {
      if (!inputValue) {
        return callback([]);
      }

      getCities({ search_city: inputValue }).then((cities: IGetCity[]) => {
        callback(
          cities.map(city => ({
            label: upperFirst(city.description),
            value: city.description,
          })),
        );
      });
    },
    300,
  );

  return (
    <StyledWrapper>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Fieldset>
          <Heading sm="24px" md="24px" lg="24px" tag="h2">
            {t('product:searchAPlaceToRent')}
          </Heading>

          <Spacer space="xs" as="fieldset">
            <Controller
              control={control}
              name="city"
              rules={{
                required: true,
              }}
              render={({ field: { value, onChange } }) => (
                <AsyncSelectInput
                  id="type"
                  label={t('product:pickACity')}
                  defaultOptions={defaultCities.map(city => ({
                    label: upperFirst(t(`geo:${city}`)),
                    value: city,
                  }))}
                  loadOptions={loadOptions}
                  optionIcon={<PropertyCity />}
                  lablelIcon={<PropertyCity />}
                  user="tenant"
                  showRequiredSymbol={false}
                  onChange={onChange}
                  value={value}
                  isClearable
                  isSearchable
                >
                  {formState.errors.city && (
                    <FormNotice isWrapped={true}>
                      {t('form:requiredField')}
                    </FormNotice>
                  )}
                </AsyncSelectInput>
              )}
            />
          </Spacer>

          <Spacer space="m" as="fieldset">
            <Controller
              control={control}
              name="type"
              rules={{
                required: false,
              }}
              render={({ field: { value, onChange } }) => (
                <DropDown
                  icon={<PropertyType />}
                  id="type"
                  name="type"
                  label={t('product:pickAPropertyType')}
                  user="tenant"
                  value={value}
                  options={properties
                    /* TODO remove the following filter when DB types
                    will be in sync with listing types */
                    .filter(property => property.key !== 'entireProperty')
                    .map(property => {
                      return {
                        text: t(`product:${property.key}`),
                        value: property.key,
                      };
                    })}
                  onChange={onChange}
                ></DropDown>
              )}
            />
          </Spacer>

          <Spacer space="m" as="fieldset">
            <Controller
              control={control}
              name="price"
              rules={{
                required: false,
              }}
              render={({ field: { value, onChange } }) => (
                <RangeSlider
                  ariaLabels={{
                    valueMax: t('a11y:valueMax'),
                    valueMin: t('a11y:valueMin'),
                  }}
                  hasPlus={true}
                  id="price"
                  label={t('product:monthlyFee')}
                  max={2500}
                  min={0}
                  symbol="€"
                  step={250}
                  user="tenant"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Spacer>

          <div>
            <CtaButton
              type="submit"
              user="tenant"
              width="100%"
              disabled={searchDisabled}
            >
              {upperCase(t('form:search'))}
            </CtaButton>
          </div>
        </Fieldset>
      </form>
      <StyledLandordLink>
        <StyledParagraph>{t(`product:areYouALandlord`)}</StyledParagraph>
        <StyledLink
          title={upperFirst(t(`product:rentYourHomeAndEarnMoney`))}
          to="/it/pubblica-annuncio-gratis"
        >
          {t(`product:publishYourPostForFree`)}
        </StyledLink>
      </StyledLandordLink>
    </StyledWrapper>
  );
};

export default FindAnApartment;
