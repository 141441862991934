import { getAspectRatioRate } from '@zappyrent/hundop';
import { cover, rem } from 'polished';
import styled from 'styled-components';

export const StyledFindAnApartmentWrapper = styled.div`
  scroll-margin-top: ${rem('80px')};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
    position: absolute;
    top: ${rem('100px')};
    right: 0;
    z-index: 100;
  }
`;

export const StyledHead = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.l}) {
    margin-bottom: ${rem('-28px')};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
    position: absolute;
    top: ${rem('40px')};
    left: 0;
    z-index: 100;
  }
`;

export const StyledBody = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
    display: flex;
    justify-content: center;
    flex-grow: 1;
  }
`;

export const StyledSliderOuterWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${getAspectRatioRate(375, 250)};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
    padding-top: ${getAspectRatioRate(702, 529)};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding-top: ${getAspectRatioRate(668, 520)};
  }
`;

export const StyledSliderInnerWrapper = styled.div`
  ${cover()}
`;

export const StyledSliderImage = styled.img`
  display: block;
  height: auto;
  width: 100%;
`;

export const StyledWrapper = styled.div`
  padding: ${rem('10px')} 0 0 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
    padding: 0 0 ${rem('16px')} 0;
  }
`;
