import { Link } from 'gatsby';
import { rem, rgba } from 'polished';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  width: 100%;
  margin-top: ${rem('24px')};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
    margin: 0;
    padding: ${rem('32px')} ${rem('16px')} ${rem('16px')};
    background-color: ${({ theme }) => theme.colors.main};
    border-radius: ${({ theme }) => rem(theme.spacings.s)};
    box-shadow: 0 0 ${rem('8px')} 0
      ${({ theme }) => rgba(theme.colors.cross.silverFox, 0.5)};
  }
`;

export const StyledLandordLink = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: ${rem('15px')};
  margin-top: ${rem('16px')};
`;

export const StyledParagraph = styled.p`
  margin: 0;
`;

export const StyledLink = styled(Link)`
  margin: 0;
  color: ${({ theme }) => theme.colors.tenant.main};
  font-weight: 600;
  text-decoration: none;
`;
